import React, {useState, useEffect}                                           from 'react'
import {Checkbox, Space, Typography, Modal, Button} from 'antd'
import {ActionProps}                                               from '../types'
import {AupConfig}                                                 from '../types/AupActionConfig'
import FormItem                                                    from 'antd/lib/form/FormItem';
import { useLazyGetFileUrlQuery }                                   from '../api/enrollement';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useIntl } from 'react-intl'


const AupAction: React.FC<ActionProps<AupConfig>> = ({ config, form, setDisableContinue }) => {

  useEffect(() => {
    if (form.getFieldValue('acceptAup') !== undefined) {
      setDisableContinue(!form.getFieldValue('acceptAup'))
    } else {
      setDisableContinue(!config.checkboxDefaultState)
    }
    getFile()
   }, []);

  const [getFileUrl] = useLazyGetFileUrlQuery()
  const [fileUrl, setfileUrl] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { Text, Link } = Typography


  const getFile = () => {
    if (config.useAupFile && config.aupFileLocation) {
      getFileUrl({fileId: config.aupFileLocation}).unwrap()
        .then((payload) => setfileUrl(payload.url))
     }
    }

  const { $t } = useIntl()
  return (
    <><Space direction='vertical' align='center'>
      <span dangerouslySetInnerHTML={{ __html: config.messageHtml }} />
      <FormItem
        name='acceptAup'
        initialValue={config.checkboxDefaultState}
        valuePropName="checked"
      >
        <Checkbox data-testid='acceptAup' onChange={(e: CheckboxChangeEvent) => setDisableContinue(!e.target.checked)}>
          {config.useAupFile ?
            <Text>I agree to the <Link href={fileUrl}>{$t({ defaultMessage: 'Terms & Conditions' })}</Link></Text> :
            <Text>I agree to the <Link onClick={showModal}>{$t({ defaultMessage: 'Terms & Conditions' })}</Link></Text>}
        </Checkbox>
      </FormItem>
    </Space>
      <Modal
        open={isModalOpen}
        closable={false}
        footer={
          <Button type='primary' onClick={handleClose}>
            Close
          </Button>
        }>
        <span dangerouslySetInnerHTML={{ __html: config.aupPlainText || ''}} />
      </Modal></>
  )
}

export default AupAction
